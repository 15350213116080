export const appHead = {"meta":[{"charset":"utf-8"},{"name":"description","content":"Gumart"},{"name":"fb:app_id","content":""},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"},{"hid":"og:image","property":"og:image","content":"/favicon.ico"},{"hid":"twitter:image","property":"twitter:image","content":"/favicon.ico"},{"hid":"twitter:description","property":"twitter:description","content":""},{"hid":"twitter:card","property":"twitter:card","content":""}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.ico"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"href":"https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap","rel":"stylesheet"}],"style":[],"script":[{"src":"/ethers.min.js","type":"text/javascript","async":"true"},{"src":"/metamask-sdk.js","type":"text/javascript","async":"true"},{"src":"https://telegram.org/js/telegram-web-app.js","type":"text/javascript","onload":"window.Telegram.WebApp.expand(); window.Telegram.WebApp.setHeaderColor('#FFF'); window.Telegram.WebApp.setBackgroundColor('#FFF'); window.Telegram.WebApp.disableVerticalSwipes();"},{"src":"https://unpkg.com/@tonconnect/sdk@latest/dist/tonconnect-sdk.min.js","type":"text/javascript"}],"noscript":[],"title":"Gumart","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false