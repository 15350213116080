import axios from 'axios'
export function request() {
  const apiBaseUrl = useRuntimeConfig().public.apiBase
  const headersParams = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
  const instance = axios.create({
    baseURL: apiBaseUrl,
    headers: headersParams,
  })
  return instance
}

export async function getRequest(url, params) {
  try {
    return await request().get(url, { params: { ...params } })
  } catch (e) {
    console.log(e)
  }
}
export async function postRequest(url, body) {
  try {
    return await request().post(url, body)
  } catch (e) {
    console.log(e)
  }
}
