import UserInfo from '~/store/user'
export default defineNuxtRouteMiddleware((to, from) => {
  const { queryId } = storeToRefs(UserInfo())
  const user = UserInfo()
  const excludedRoutes = ['/']
  const refId = to.query?.tgWebAppStartParam as any
  const queryMode = to.query?.mode as any
  user.setQueryData(refId, queryMode)
  if (!queryId.value && !excludedRoutes.includes(to.path)) {
    return navigateTo('/')
  }
})
