import { default as checking_45accountenfh5OrO79Meta } from "/Users/bachoang/bekisoft/gumart-fe/pages/checking-account.vue?macro=true";
import { default as homelDb5SgCgCrMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/home.vue?macro=true";
import { default as indexmeDB53MLViMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/index.vue?macro=true";
import { default as missionLhLC2s96xZMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/mission.vue?macro=true";
import { default as not_45data0RlhTIOayIMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/not-data.vue?macro=true";
import { default as onboardXnIGRnQowfMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/onboard.vue?macro=true";
import { default as partnersOcRhqTSWW8Meta } from "/Users/bachoang/bekisoft/gumart-fe/pages/partners.vue?macro=true";
import { default as shopNPUvwKtxrJMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/shop.vue?macro=true";
import { default as tier1wKEuqMMF6Meta } from "/Users/bachoang/bekisoft/gumart-fe/pages/tier.vue?macro=true";
import { default as total_45gum19DfDKaJEeMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/total-gum.vue?macro=true";
import { default as walletQ9yL1oRliXMeta } from "/Users/bachoang/bekisoft/gumart-fe/pages/wallet.vue?macro=true";
import { default as welcomeecI1r0FAe7Meta } from "/Users/bachoang/bekisoft/gumart-fe/pages/welcome.vue?macro=true";
export default [
  {
    name: checking_45accountenfh5OrO79Meta?.name ?? "checking-account",
    path: checking_45accountenfh5OrO79Meta?.path ?? "/checking-account",
    meta: checking_45accountenfh5OrO79Meta || {},
    alias: checking_45accountenfh5OrO79Meta?.alias || [],
    redirect: checking_45accountenfh5OrO79Meta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/checking-account.vue").then(m => m.default || m)
  },
  {
    name: homelDb5SgCgCrMeta?.name ?? "home",
    path: homelDb5SgCgCrMeta?.path ?? "/home",
    meta: homelDb5SgCgCrMeta || {},
    alias: homelDb5SgCgCrMeta?.alias || [],
    redirect: homelDb5SgCgCrMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexmeDB53MLViMeta?.name ?? "index",
    path: indexmeDB53MLViMeta?.path ?? "/",
    meta: indexmeDB53MLViMeta || {},
    alias: indexmeDB53MLViMeta?.alias || [],
    redirect: indexmeDB53MLViMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: missionLhLC2s96xZMeta?.name ?? "mission",
    path: missionLhLC2s96xZMeta?.path ?? "/mission",
    meta: missionLhLC2s96xZMeta || {},
    alias: missionLhLC2s96xZMeta?.alias || [],
    redirect: missionLhLC2s96xZMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: not_45data0RlhTIOayIMeta?.name ?? "not-data",
    path: not_45data0RlhTIOayIMeta?.path ?? "/not-data",
    meta: not_45data0RlhTIOayIMeta || {},
    alias: not_45data0RlhTIOayIMeta?.alias || [],
    redirect: not_45data0RlhTIOayIMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/not-data.vue").then(m => m.default || m)
  },
  {
    name: onboardXnIGRnQowfMeta?.name ?? "onboard",
    path: onboardXnIGRnQowfMeta?.path ?? "/onboard",
    meta: onboardXnIGRnQowfMeta || {},
    alias: onboardXnIGRnQowfMeta?.alias || [],
    redirect: onboardXnIGRnQowfMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/onboard.vue").then(m => m.default || m)
  },
  {
    name: partnersOcRhqTSWW8Meta?.name ?? "partners",
    path: partnersOcRhqTSWW8Meta?.path ?? "/partners",
    meta: partnersOcRhqTSWW8Meta || {},
    alias: partnersOcRhqTSWW8Meta?.alias || [],
    redirect: partnersOcRhqTSWW8Meta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: shopNPUvwKtxrJMeta?.name ?? "shop",
    path: shopNPUvwKtxrJMeta?.path ?? "/shop",
    meta: shopNPUvwKtxrJMeta || {},
    alias: shopNPUvwKtxrJMeta?.alias || [],
    redirect: shopNPUvwKtxrJMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: tier1wKEuqMMF6Meta?.name ?? "tier",
    path: tier1wKEuqMMF6Meta?.path ?? "/tier",
    meta: tier1wKEuqMMF6Meta || {},
    alias: tier1wKEuqMMF6Meta?.alias || [],
    redirect: tier1wKEuqMMF6Meta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/tier.vue").then(m => m.default || m)
  },
  {
    name: total_45gum19DfDKaJEeMeta?.name ?? "total-gum",
    path: total_45gum19DfDKaJEeMeta?.path ?? "/total-gum",
    meta: total_45gum19DfDKaJEeMeta || {},
    alias: total_45gum19DfDKaJEeMeta?.alias || [],
    redirect: total_45gum19DfDKaJEeMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/total-gum.vue").then(m => m.default || m)
  },
  {
    name: walletQ9yL1oRliXMeta?.name ?? "wallet",
    path: walletQ9yL1oRliXMeta?.path ?? "/wallet",
    meta: walletQ9yL1oRliXMeta || {},
    alias: walletQ9yL1oRliXMeta?.alias || [],
    redirect: walletQ9yL1oRliXMeta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/wallet.vue").then(m => m.default || m)
  },
  {
    name: welcomeecI1r0FAe7Meta?.name ?? "welcome",
    path: welcomeecI1r0FAe7Meta?.path ?? "/welcome",
    meta: welcomeecI1r0FAe7Meta || {},
    alias: welcomeecI1r0FAe7Meta?.alias || [],
    redirect: welcomeecI1r0FAe7Meta?.redirect,
    component: () => import("/Users/bachoang/bekisoft/gumart-fe/pages/welcome.vue").then(m => m.default || m)
  }
]